import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
import Sidebar from "../../../../components/sidebar";
import Outcomeitem from "../../../../components/outcomelist";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Being Here`}</h1>
    <h4>{`March 11–14, 2010 | Marfa, TX`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAgME/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAbSu0qe0Uh//xAAaEAACAgMAAAAAAAAAAAAAAAAAAQISAwQi/9oACAEBAAEFAqlUcj2GnLIXZ//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwGq/8QAFRABAQAAAAAAAAAAAAAAAAAAECH/2gAIAQEABj8CY//EABgQAQEBAQEAAAAAAAAAAAAAAAEAETFB/9oACAEBAAE/IQWVkInnuypO3b//2gAMAwEAAgADAAAAEJQP/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QJf/EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxA2n//EABoQAQEAAgMAAAAAAAAAAAAAAAEAESFBUWH/2gAIAQEAAT8QQBgyztIQBxFUJr2eEoBbJbX/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Being Here",
          "title": "Being Here",
          "src": "/static/79089d745b9584234831b052e3255e80/e5166/IMG_7042.jpg",
          "srcSet": ["/static/79089d745b9584234831b052e3255e80/f93b5/IMG_7042.jpg 300w", "/static/79089d745b9584234831b052e3255e80/b4294/IMG_7042.jpg 600w", "/static/79089d745b9584234831b052e3255e80/e5166/IMG_7042.jpg 1200w", "/static/79089d745b9584234831b052e3255e80/d9c39/IMG_7042.jpg 1800w", "/static/79089d745b9584234831b052e3255e80/df51d/IMG_7042.jpg 2400w", "/static/79089d745b9584234831b052e3255e80/44e20/IMG_7042.jpg 4416w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Sidebar mdxType="Sidebar">
    <Outcomeitem link="https://archive.designinquiry.net/contributions/losing-control/" title="Losing Control by Helen Armstrong" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/finding-the-here-ness-of-letters-a-proposal/" title="Finding the Here-ness of Letters: A Proposal by David Shields" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/designing-the-immaterial/" title="Designing the Immaterial by Eric Benson" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/the-marfa-unsolved-mystery-lights/" title="The Marfa Unsolved Mystery Lights by Christopher Moore" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/marfa-displaced/" title="Marfa Displaced by Christopher Moore" mdxType="Outcomeitem" />
    </Sidebar>
    <p><strong parentName="p">{`Our first 4-day Spring Inquiry was held in the high desert town of Marfa, in far west Texas. With mesquite, cacti, tumbleweed, pristine air and the distant Chinati and Davis mountains, Marfa has a spare and sublime atmosphere that literally suggests a site-specific creativity. The minimalist sculptor Donald Judd relocated here from New York in 1971, taking over a former military compound and setting his house in a field of gravel; the crunch underfoot was meant to raise awareness, as Judd put it, of every footfall. Many artists have followed Judd’s path, and the sleepy town now hosts a surprisingly well-stocked bookstore, several galleries, the Chinati Foundation, and some fine eating establishments. Nights are cold but as the desert warms in the sun, Judd’s 100 aluminum boxes can be heard popping on a cement floor, and the winds sweeping through town are punctuated by the occasional Amtrak train whistle.`}</strong></p>
    <p><strong parentName="p">{`Being in Marfa was an opportunity to explore an approach to design that is contemplative and reactive, a design that does not prescribe or impose formulas but responds to the conditions that surround it. BeingHERE challenged design as an act of premeditation, and sought instead to situate it in time and place, be it in Marfa or elsewhere.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      